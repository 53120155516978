<template>
  <!-- layout-container lesson 1 -->
  <div class="lesson-one-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
  </div>
</template>
<script>
import SyllabusPageA from "@/views/Course/Level1/Chapter1/Lesson7/3_SyllabusPage_A.vue";
import LessonPage from "@/views/Course/Level1/Chapter1/Lesson7/1_Lesson.vue";
import TargetPage from "@/views/Course/Level1/Chapter1/Lesson7/3_Target.vue";
import RecognitionPageOne from "@/views/Course/Level1/Chapter1/Lesson7/7_RecognitionPage_a.vue";
import RecognitionPageTwo_ni from "@/views/Course/Level1/Chapter1/Lesson7/8_RecognitionPage_o.vue";
import RecognitionPageTwo_hao from "@/views/Course/Level1/Chapter1/Lesson7/9_RecognitionPage_e.vue";
import RecognitionPageThree from "@/views/Course/Level1/Chapter1/Lesson7/12_RecognitionPage_i.vue";
import RecognitionPageFour from "@/views/Course/Level1/Chapter1/Lesson7/13_RecognitionPage_u.vue";
import WordsCardPage from "@/views/Course/Level1/Chapter1/Lesson7/14_RecognitionPage_yu.vue";
import SummaryPage from "@/views/Course/Level1/Chapter1/Lesson7/16_WordsCardPage.vue";
import RecognitionPage_tone_a from "@/views/Course/Level1/Chapter1/Lesson7/18_RecognitionPage_tone_a.vue";
import RecognitionPage_tone_o from "@/views/Course/Level1/Chapter1/Lesson7/19_RecognitionPage_tone_o.vue";
import RecognitionPage_tone_e from "@/views/Course/Level1/Chapter1/Lesson7/20_RecognitionPage_tone_e.vue";
import RecognitionPage_tone_i from "@/views/Course/Level1/Chapter1/Lesson7/22_RecognitionPage_tone_i.vue";
import RecognitionPage_tone_u from "@/views/Course/Level1/Chapter1/Lesson7/23_RecognitionPage_tone_u.vue";
import RecognitionPage_tone_yu from "@/views/Course/Level1/Chapter1/Lesson7/24_RecognitionPage_tone_yu.vue";
import PinyinSummary from "@/views/Course/Level1/Chapter1/Lesson7/30_PinyinSummary.vue";
import ByePage from "@/views/Course/Level1/Chapter1/Lesson7/32_ByePage.vue";

export default {
  name: "PreviewLesson47",
  components: {
    SyllabusPageA,
    LessonPage,
    TargetPage,
    RecognitionPageOne,
    RecognitionPageTwo_ni,
    RecognitionPageTwo_hao,
    RecognitionPageThree,
    RecognitionPageFour,
    WordsCardPage,
    SummaryPage,
    RecognitionPage_tone_a,
    RecognitionPage_tone_o,
    RecognitionPage_tone_e,
    RecognitionPage_tone_i,
    RecognitionPage_tone_u,
    RecognitionPage_tone_yu,
    PinyinSummary,
    ByePage,
  },
  mounted() {
    this.$bus.$on("previewSwitchPageType", (value) => {
      this.pageType = value;
    });
    this.$bus.$on("previewJumpPage", (value) => {
      this.pageNum = value;
    });
  },
  destroyed() {
    this.$bus.$off("previewSwitchPageType");
    this.$bus.$off("previewJumpPage");
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        TargetPage,
        RecognitionPageOne,
        RecognitionPageTwo_ni,
        RecognitionPageTwo_hao,
        RecognitionPageThree,
        RecognitionPageFour,
        WordsCardPage,
        SummaryPage,
        RecognitionPage_tone_a,
        RecognitionPage_tone_o,
        RecognitionPage_tone_e,
        RecognitionPage_tone_i,
        RecognitionPage_tone_u,
        RecognitionPage_tone_yu,
        PinyinSummary,
        ByePage,
      },
      pageNum: 1,
      pageType: 0,
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-one-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
